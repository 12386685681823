import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./AssessmentsAndPlans.settingsForm";

const AssessmentsAndPlansComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessmentsAndPlans: props.value || ""
    };
  }

  onChangeEvent(event) {
    const assessmentsAndPlans = event.target.value;
    this.setState({ assessmentsAndPlans }, () => {
      this.props.onChange(this.state.assessmentsAndPlans);
    });
  }

  getValue() {
    return this.state.assessmentsAndPlans;
  }

  setValue(value) {
    this.setState({ assessmentsAndPlans: value || "" });
  }

  render() {
    $(".component-edit-container p.lead").html("Assessment and Plan Widget");
    return (
      <div id={this.props.component.key}>
        <div className="assesments-and-plans-widget">
          <div id="">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <label className="control-label bold premium-color">
                      Assessment and Plan
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="w-100 bg-white"
                    style={{ resize: "none" }}
                    rows={7}
                    id='AssessmentsAndPlansCompInput'
                    value={this.state.assessmentsAndPlans}
                    onChange={(event) => this.onChangeEvent(event)}
                    onBlur={(event) => this.onChangeEvent(event)}
                    onKeyUp={(event) => this.onChangeEvent(event)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class AssessmentsAndPlans extends ReactComponent {
  static get builderInfo() {
    return {
      title: "Assessment and Plan",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: AssessmentsAndPlans.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "assessmentsAndPlansComp",
    });
  }

  static editForm = settingsForm;

  attachReact(element) {
    return ReactDOM.render(
      <AssessmentsAndPlansComp
        component={this.component}
        value={this.dataValue}
        onChange={this.updateValue}
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap"
import "bootstrap-select"
import "select2"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("metisMenu/jquery.metisMenu")
require("slimscroll/jquery.slimscroll.min")
require("packs/inspinia")
require("packs/bootstrap-select")
require("dataTables/datatables.min")
require("dataTables/dataTables.bootstrap4.min")
require("packs/h-smart-thumbnail")
require("packs/jquery.validate")
require("packs/additional-methods")
require("packs/bootstrap-datepicker")
require("packs/jquery.mask")
require("packs/icheck.min")
require("packs/sweetalert.min")
require("packs/ajax-bootstrap-select.min")
// require("packs/jquery.raty")
// require("packs/ratyrate")
// require("packs/imagesloaded.min")
require("packs/custom")
require("packs/rails_sortable")
require("packs/jquery_nested_form")
global.toastr = require("toastr")

Rails.start()
Turbolinks.start()
ActiveStorage.start()


import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
// const context = require.context("controllers", true, /.js$/)
// application.load(definitionsFromContext(context))

// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

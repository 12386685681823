import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./MedicalDiagnosis.settingsForm";
import axios from 'axios';
import renderHTML from 'react-render-html';
import { BASE_URL, LOCAL_URL } from '../../helpers/globalPaths';
import ViewModeImage from 'images/view_mode_details.svg'


/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const MedicalDiagnosisComp = class extends Component { 

   constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      medicalDiagnosis: {
        widget_data: [],
      },
    };
    this.props.onChange(this.state.medicalDiagnosis);
  }


  getValue() {
    return this.state.medicalDiagnosis;
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        medicalDiagnosis: {
          widget_data: value?.widget_data || [],
        },
      });
    }
  }


  render() {
    $(".component-edit-container p.lead").html("Medical Diagnosis Widget");
    return (
      <div id={this.state.component.key}>
        <div>
          <h3>Medical Diagnosis</h3>
          <div className="widget-component-btn">
            <small className="float-right mr-2 d-none">
              <a href="" data-method="post" data-remote="true">
                <i className="fa fa-sync"></i>
              </a>
            </small>
            <img 
              id="reorderMedModal"
              width="25"
              height="25"
              style={{cursor: 'pointer','userSelect': 'none', 'float': 'right', 'marginTop': '-8px', 'paddingBottom': '4px'}}
              src={ViewModeImage}
               />
          </div>
          <div className="medical-diagnosis-widget">
            <table className="widget-table-border w-100">
              <thead className="sb-table-header">
                <tr>
                  <td>DIAGNOSIS</td>
                  <td>ICD-10</td>
                  <td>DATE ADDED</td>
                  <td>STATUS</td>
                </tr>
              </thead>
              <tbody className="sb-table-body-psych-widget">
                {this.state.medicalDiagnosis && this.state.medicalDiagnosis.widget_data.length > 0 && this.state.medicalDiagnosis.widget_data.map(item => {
                  return (
                    <tr key={item.guid} id={item.guid}  data-form-id={item.widgetable_id} >
                      <td>
                        <span className="sb-field" data-id={ item.guid }>
                          { item.problem_label }
                        </span>
                      </td>
                      <td>
                        <span className="sb-field" data-id={ item.guid }>
                          { item.problem_code }
                        </span>
                      </td>
                      <td>
                        <span className="sb-field" data-id={ item.guid }>
                          { item.date_label }
                        </span>
                      </td>
                      <td>
                        <span className="sb-field" data-id={ item.guid }>
                          { item.status }
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

export default class MedicalDiagnosis extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Medical Diagnosis",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: MedicalDiagnosis.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "medicalDiagnosisComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <MedicalDiagnosisComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

   getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./HistoryOfPresentIllness.settingsForm";

const PresentIllnessComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presentIllness: props.value || ""
    };
  }
  
  onChangeEvent(event) {
    const presentIllness = event.target.value;
    this.setState({ presentIllness }, () => {
      this.props.onChange(presentIllness);
    });
  }

  getValue() {
    return this.state.presentIllness;
  }

  setValue(value) {
    this.setState({ presentIllness: value });
  }

  render() {
    $(".component-edit-container p.lead").html("History of Present Illness Widget");
    return (
      <div id="present-illness-component">
        <div className="present-illness-widget">
          <div id="historyofpresentillness">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <label className="control-label bold premium-color">
                      HISTORY OF PRESENT ILLNESS: Describe the Onset of Illness,
                      Intensity of Symptoms, and Circumstances Leading to This
                      Visit
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="w-100 bg-white"
                    style={{ resize: "none" }}
                    id={this.props.component.key}
                    rows={10}
                    value={this.state.presentIllness || ""}
                    onChange={(event) => this.onChangeEvent(event)}
                    onBlur={(event) => this.onChangeEvent(event)}
                    onKeyUp={(event) => this.onChangeEvent(event)}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class PresentIllness extends ReactComponent {
  static get builderInfo() {
    return {
      title: "History of Present Illness",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: PresentIllness.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "presentIllnessComp",
    });
  }

  static editForm = settingsForm;

  attachReact(element) {
    return ReactDOM.render(
      <PresentIllnessComp
        component={this.component}
        value={this.dataValue || ""}
        onChange={this.updateValue}
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./ProcedureCode.settingsForm";
import axios from 'axios';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const ProcedureCodeComp = class extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      procedures: {
        widget_data: [],
      },
    };
    this.props.onChange(this.state.procedures);
  }
  getValue() {
    return this.state.procedures;
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        procedures: {
          widget_data: value?.widget_data || [],
        },
      });
    }
  }

  render() {
    $(".component-edit-container p.lead").html("Procedures Code");
    return (
      <div id={this.state.component.key}  className="procedure-code-widget">
        <h3>Procedure Codes</h3>
        <div className="row">
          <div className="col-sm-12">
            <table className="widget-table-border w-100">
              <thead className="sb-table-header">
                <tr>
                  <td width="40%">Procedure Codes</td>
                  <td width="15%">Modifiers</td>
                  <td width="5%">Unit</td>
                  <td width="20%">Associated Diagnosis</td>
                </tr>
              </thead>
              <tbody id="procedure_codes_body">
              {this.state.procedures && this.state.procedures.widget_data.length > 0 && this.state.procedures.widget_data.map(item => {
                  return (
                    <tr key={item.guid} id={item.guid} data-form-id={item.widgetable_id}  >
                      <td>
                        <span className="sb-field" >
                           {item.procedure_code || ""}&nbsp;{item.procedure_title || ""}
                        </span>
                      </td>
                      <td>
                        <div className="input-group w-100" >
                          <div className="procedures-code"  style={{borderRight: '1px solid'}} >
                              {item.modifier1 || ""}
                          </div> 
                          <div className="procedures-code" style={{borderRight: '1px solid',  padding: '0px 7px' }} >
                              {item.modifier2 || ""}
                          </div>
                          <div className="procedures-code" style={{padding: '0px 7px' }} >
                              {item.modifier3 || ""}
                          </div>
                        </div>
                      </td>
                      <td>
                         {item.unit || ""}
                      </td>
                      <td>
                        <span className="sb-field" style={{width: '100%'}}>
                          <span>{item.icd1 || ""} &nbsp;</span>
                          <span>{item.icd2 || ""} &nbsp;</span>
                          <span>{item.icd3 || ""} &nbsp;</span>
                          <span>{item.icd4 || ""} </span>
                      </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

export default class ProcedureCode extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Procedure Codes Widget",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: ProcedureCode.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "procedureCodeComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <ProcedureCodeComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
    getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}

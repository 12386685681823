import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./DateTime.settingsForm";
import axios from 'axios';
import renderHTML from 'react-render-html';
import { BASE_URL, LOCAL_URL } from '../../helpers/globalPaths';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const DateTimeComp = class extends Component { 

  constructor(props) {
    super(props);
    this.state = {
      component: props.component,
      value: props.value,
      widget_data: {}
    };
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        widget_data: value?.widget_data || {},
      });
    }
  }

  openEditModal = (data) => {
    var canEdit = $("#note-can-edit").val();
    if (canEdit == "true") {
      if(data && !(jQuery.isEmptyObject(data.widget_data))){
        $.post('/form_builders/widgets/new_edit_sb_attr', { 
          attr_type: 'datetime',
          patient_guid: data.widget_data.patient_guid,
          id: data.widget_data.widgetable_id,
          patient_form_guid: $('#patient_note_guid').val(),
        });
      }
      else {
        this.openCreateModal();
      }
    }
  }

  openCreateModal = () =>{
    $.post('/form_builders/widgets/new_edit_sb_attr', {
      attr_type: 'datetime',
      patient_guid: $('#patient_guid').val(),
      patient_form_guid: $('#patient_note_guid').val()
    });
  }

  render() {
    $(".component-edit-container p.lead").html("Date/Time Spent Widget");
    const { value } = this.state
    return (
      <div id={this.props.component.key}>
        <h3>Date/Time Spent:</h3>
        <div className="date-time-widget">
          <span className="comp-label"></span>
          <div className="row pointer" data-id={this.state.widget_data?.guid} onClick={() => this.openEditModal(this.state)} >
            <div className=" col-12 mx-0">
              <div className="input-group">
                <input 
                  id="dt_date"
                  className="sb-field date line-input name form-control pb-3 pointer"
                  value={this.state?.widget_data?.datetime}
                  placeholder="--/--/---- --:-- - --:--"
                  autocomplete="off"

                  />
              </div>   
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class DateTime extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Date/Time Spent",
      icon: "calendar",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: DateTime.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "datetimeComp"
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <DateTimeComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}

      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}

import React, { Component } from 'react';

class UndoRedoComponent extends Component {
  render() {
    const { handleUndo, handleRedo, canEdit, patientFormGuid, patientGuid, formGuid} = this.props;
    console.log("UndoRedoComponent", this)
    return (
      <div className="undo-redo-div d-flex pl-3">
        {canEdit == "true" ? (
          <div className="d-flex">
            <div
                className={`undo-div pointer text-center no-select`}
                onClick={handleUndo}
              >
                
                <small>Undo</small>
              </div>
              <div className="pt-3 text-center">
                <div className="vl-grey-short"></div>
              </div>
              <div
                className={`redo-div pointer text-center no-select`}
                onClick={handleRedo}
              >
               
                <small>Redo</small>
              </div>
          
              
              <div>
                <span id="save-timestamp-confirmation" style={{color: "#ACAAAB", right: "28rem", position: 'absolute'}}></span>
                <button className="btn btn-primary btn-md-form mt-1 d-none" id="toolbar-save-form" type="button" >Save</button>
              </div>
          </div>
        ) : null}


      </div>
    );
  }
}

export default UndoRedoComponent;

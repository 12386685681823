import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./PastInpatientPsychiatricHistory.settingsForm";

const PastInpatientComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || "",
      widget_data: "",
      pastInpatient: "",
    };
  }

  onChangeEvent(event) {
    const pastInpatient = event.target.value;
    this.setState({ pastInpatient }, () => {
      this.props.onChange(this.state.pastInpatient);
    });
  }

  getValue() {
    return this.state.pastInpatient;
  }

  setValue(value) {
    this.setState({ pastInpatient: value || "" });
  }

  render() {
    $(".component-edit-container p.lead").html("Past Inpatient Widget");
    return (
      <div id={this.props.component.key}>
        <div className="past-inpatient-widget">
          <div id="past-inpatient">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <label className="control-label bold premium-color">
                      PAST INPATIENT PSYCHIATRIC HISTORY: Prior Treatment,
                      Precipitating Factors, Diagnosis, Course of Treatment
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="w-100 bg-white"
                    style={{ resize: "none" }}
                    id="pastInpatientCompInput"
                    rows={3}
                    value={this.state.pastInpatient}
                    onChange={(event) => this.onChangeEvent(event)}
                    onBlur={(event) => this.onChangeEvent(event)}
                    onKeyUp={(event) => this.onChangeEvent(event)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class PastInpatient extends ReactComponent {
  static get builderInfo() {
    return {
      title: "Past Inpatient History",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: PastInpatient.schema(),
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "pastInpatientComp",
    });
  }

  static editForm = settingsForm;

  attachReact(element) {
    return ReactDOM.render(
      <PastInpatientComp
        component={this.component}
        value={this.dataValue}
        onChange={this.updateValue}
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }


  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }

  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}

import React from "react";
import { Components, Form } from "@formio/react";
import components from "./Custom";
import $ from "jquery";

import { transformPremiumWidgetData } from "./Undo-Redo/premiumWidgetsHelpers";

Components.setComponents(components);


class RenderFormBuilder extends React.Component {
  constructor(props) {
    super(props);

    var data = props.form_builder_data.value || props.last_saved_form_data || {};

    const form = JSON.parse(props.form_builder.content);
    const premiumComponentsInData = form.components.filter(
      (component) =>
        ["psychROSComp", "psychRosComp", "MentalStatusExamComp"].includes(
          component["type"]
        )
    );

    premiumComponentsInData.forEach((component)=>{
      data[component["key"]] = transformPremiumWidgetData(component, data && data[component["key"]] ? data[component["key"]] : null);
    })

    this.formRef = React.createRef();
    this.state = {
      form: form,
      data: data,
    };
  }


  render() {
    const { form, data } = this.state;
    const patientGuid = this.props.patient_guid
    const patientFormGuid = this.props.patient_form_guid
    const appointmentGuid = this.props.appointment_guid
    const formGuid = this.props.form_builder.guid
    const type = this.props.type


    form["components"].push({
      "textFieldLabel": "Name",
      "type": "hiddenFieldCustomComp",
      "input": true,
      "key": "hiddenFieldCustomComp"
    },
    {
      "type": "button",
      "label": "Submit",
      "key": "submit",
      "disableOnInvalid": true,
      "input": true,
      "tableView": false,
      "customClass": "form-builder-submit-btn d-none"
      });
    var canEdit = $("#note-can-edit").val();

    return (
      <div id="form-builder-div">
        <div className="form-div">
          <Form
            form={form}
            submission={{ data }}
          />
        </div>
      </div>
    );
  }
}

export default RenderFormBuilder;
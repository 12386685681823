import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./TextArea.settingsForm";

export default class TextArea extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Text Field Multiple Lines",
      icon: "bars",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: TextArea.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "textAreaCustomComp",
      textAreaLabel: "",
      textAreaFieldSize: 10,
      textAreaPlaceholder: "",
      colorTheme: "bg-white",
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Text Field Multiple Lines");

    let textarea_label = "";
    textarea_label += `<span class="comp-label ${this.component.requiredSaveNote ? 'field-required' : ''}">`;
    textarea_label += `${this.component.textAreaLabel || ''}`;
    textarea_label += `</span>`;
    if (this.component.addTooltip && this.component.tooltipDescription){
      textarea_label += ` <i class="fa fa-question-circle text-muted" ref="tooltip" data-title="${this.component.tooltipDescription}"></i>`;
    }

    var textFieldMultipleLines = ''
    var attr_json = {
                      id: `${this.component.key}-textFieldMultipleLines`,
                      class: `w-100 ${this.component.colorTheme} ${this.component.requiredSaveNote ? 'required' : ''}`,
                      placeholder: `${this.component.textAreaPlaceholder || ''}`,
                      rows: `${this.component.textAreaFieldSize}`
                    }
    if (this.component.requiredSaveNote){
      attr_json.required = 'required'
    }

    var textFieldMultipleLines = this.renderTemplate('input', {
      input: {
        type: 'textarea',
        ref: `${this.component.key}-textFieldMultipleLines`,
        attr: attr_json
      }
    });

    return super.render(`
      <div id="${this.component.key}">
        <div class="${this.component.colorTheme} d-flex justify-content-between">
          <div>
            ${textarea_label}
          </div>
        </div>
        <div class="form-group">
          ${textFieldMultipleLines}
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    refs[`${this.component.key}-textFieldMultipleLines`] = '';

    this.loadRefs(element, refs);

    this.addEventListener(this.refs[`${this.component.key}-textFieldMultipleLines`][0], 'keyup', () => this.updateValue());

    return super.attach(element);
  }

  getValue() {
    var value = '';
    value = this.refs[`${this.component.key}-textFieldMultipleLines`][0].value;
    $(`textarea#${this.component.key}-textFieldMultipleLines`).text(value);
    return value;
  }

  setValue(value) {
    this.refs[`${this.component.key}-textFieldMultipleLines`][0].value = value;
    $(`textarea#${this.component.key}-textFieldMultipleLines`).text(value);
  }
}
